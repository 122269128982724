<template>
  <div style="width: 100%;" @click="handleClear">
    <page-header
      class="absolute"
      :title="__('Service providers')"
      style="padding: 20px 30px; width: calc(100% - 335px)"
    />
    <el-button
      class="createBtn"
      @click.stop="createContentItem"
      v-show="this.canWrite()"
      >{{ __("Create Service provider") }}</el-button
    >
    <div style="padding: 0 36px; margin-top: 136px;" v-loading="isLoading">
      <el-form class="form-container">
        <PaginationToolbar
          :sort-by-options="sortByOptions"
          :content-api="contentAPI"
          @success="$emit('replaceContent', $event)"
        >
        </PaginationToolbar>
      </el-form>
      <el-scrollbar :native="true">
        <div class="contentListContainer">
          <el-table
            ref="serviceProviderTable"
            class="list-table"
            :data="serviceProviders"
            highlight-current-row
            @row-click="handleSingleClick"
            @row-dblclick="handleDoubleClick"
            v-show="this.canRead()"
          >
            <el-table-column :label="__('ID')" prop="sp_id" />
            <el-table-column :label="__('Name')" prop="sp_name">
              <template slot-scope="scope">
                <el-row
                  type="flex"
                  justify="flex-start"
                  style="align-items: center"
                >
                  <img
                    :src="scope.row.logo_url || '/Five9Logo.jpg'"
                    @error="handleAltImg"
                    class="rounded-menu-icon"
                  />
                  <span style="margin-left: 20px;">{{
                    scope.row.sp_name
                  }}</span>
                </el-row>
              </template>
            </el-table-column>

            <el-table-column :label="__('Billing Mode')" prop="billing_mode" />
            <el-table-column :label="__('Note')" prop="note" />
          </el-table>
          <!--            <base-table-->
          <!--              ref="usersTable"-->
          <!--              base-url="/portal/service-providers"-->
          <!--              :table-data="serviceProviders"-->
          <!--              @row-click="handleSingleClick"-->
          <!--              @row-dblclick="handleDoubleClick"-->
          <!--            ></base-table>-->
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import PageHeader from "@/components/PageHeader";
import PaginationToolbar from "@/components/PaginationToolbar";
import BaseContent from "@/views/build/content/mixins/BaseContent";

export default {
  name: "ServiceProvidersList",
  mixins: [BaseContent],
  components: {
    PaginationToolbar,
    PageHeader
  },
  data() {
    return {
      sortByColumns: ["sp_name", "sp_id"],
      fetchedServiceProviders: false,
      search: "",
      filters: ["sp_name"],
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 10
      }
    };
  },

  computed: {
    ...mapState("serviceproviders", {
      serviceProviders: state => state.serviceProviders,
      isLoading: state => state.loading
    })
  },

  methods: {
    ...mapActions("serviceproviders", {
      contentAPI: "getServiceProvidersWithPagination",
      deleteContentMethod: "deleteServiceProvider",
      undoDeleteContent: "undoDeleteServiceProvider",
      getTableColumns: "getTableColumns"
    }),

    handleAltImg(event) {
      event.target.src = "/Five9Logo.jpg";
    },

    handleSingleClick(row, column, event) {
      event.stopPropagation();
      this.handleSelect(null, row);
    },

    handleDoubleClick(row, column, event) {
      event.stopPropagation();
      this.handleEdit(null, row);
    },

    handleClear() {
      this.$refs.serviceProvidersTable &&
        this.$refs.serviceProvidersTable.setCurrentRow();
      this.handleClearSelection();
    },

    handleDeleteItem(index, account) {
      this.$confirm(
        __("This will permanently delete the Service Provider. Continue?"),
        "Warning",
        {
          confirmButtonText: __("Delete"),
          cancelButtonText: __("Cancel"),
          type: "warning"
        }
      ).then(() => {
        this.handleDelete(account);
      });
    }
  },

  watch: {
    selectedItemIndex: {
      immediate: true,
      handler: function(val) {
        if (val !== -1 && val !== null && val !== undefined) {
          this.$refs.serviceProvidersTable &&
            this.$refs.serviceProvidersTable.setCurrentRow(
              this.serviceProviders[val]
            );
        } else if (val === -1) {
          this.$refs.serviceProvidersTable &&
            this.$refs.serviceProvidersTable.setCurrentRow();
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
$card-image-filter: invert(65%) sepia(43%) saturate(5014%) hue-rotate(170deg)
  brightness(104%) contrast(89%);
@import "~@/styles/content-buttons.scss";
@import "~@/styles/content-list.scss";
@import "~@/styles/pagination-toolbar.scss";
.rounded-menu-icon {
  height: 44px;
  width: 44px;
  border-radius: 50%;
  object-fit: cover;
  background: white;
}
</style>
